export const MODEL_ACTION = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete"
};

export const FIELD_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  TEXTAREA: "textarea",
  FILE: "file",
  CHECKBOX: "checkbox",
  SWITCH: "switch",
  DATETIME: "datetime",
  DATE: "date",
  TIME: "time",
  HTML: "html"
};

export const RELATION_TYPES = {
  ONE_TO_ONE: "One to one",
  ONE_TO_MANY: "One to many (read only)",
  MANY_TO_MANY: "Many to many",
  MANY_TO_MANY_COMMA: "Many to many (comma separated)",
  ONE_TO_MANY_IMAGES: "One to many (image upload)"
};

export const USER_TYPES = {
  ADMIN: 1
};
