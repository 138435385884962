/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import authenticate from "@/guards/authenticate";
import hasCrudPermission from "@/guards/hasCrudPermission";
import resetPassword from "@/guards/resetPassword";
import unAuthenticated from "@/guards/unAuthenticated";
import accessiblePages from "@/resolvers/accessiblePages";
import eventList from "@/resolvers/eventList";
import modelEdit from "@/resolvers/modelEdit";
import modelList from "@/resolvers/modelList";
import userTypes from "@/resolvers/userTypes";
import { MODEL_ACTION } from "@/shared/constants";
import store from "@/store";
import Vue from "vue";
import Router from "vue-router";
import multiguard from "vue-router-multiguard";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/auth",
      component: () => import("@/views/auth/Index"),
      redirect: "/auth/sign-in",
      children: [
        {
          path: "sign-in",
          name: "sign-in",
          beforeEnter: unAuthenticated,
          component: () => import("@/views/auth/SignIn")
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          beforeEnter: unAuthenticated,
          component: () => import("@/views/auth/ForgotPassword")
        },
        {
          path: "reset-password",
          name: "reset-password",
          beforeEnter: multiguard([unAuthenticated, resetPassword]),
          component: () => import("@/views/auth/ResetPassword")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      beforeEnter: multiguard([authenticate, accessiblePages]),
      redirect: "/app/dashboard",
      children: [
        {
          path: "/app/models/:model",
          component: () => import("@/views/app/models/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/models/List"),
              beforeEnter: modelList,
            },
            {
              path: "create",
              component: () => import("@/views/app/models/Form"),
              beforeEnter: hasCrudPermission,
              props: true,
              meta: { kind: MODEL_ACTION.CREATE }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/models/Form"),
              beforeEnter: multiguard([modelEdit, hasCrudPermission]),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/page",
          component: () => import("@/views/app/pages/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/pages/List")
            },
            {
              path: "create",
              component: () => import("@/views/app/pages/Form"),
              beforeEnter: userTypes,
              props: true,
              meta: { kind: "create" }
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/pages/Form"),
              beforeEnter: userTypes,
              props: true,
              meta: { kind: MODEL_ACTION.EDIT }
            }
          ]
        },
        {
          path: "/app/event",
          component: () => import("@/views/app/events/Index"),
          beforeEnter: eventList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/events/List")
            }
          ]
        },
        {
          path: "/app/dashboard",
          component: () => import("@/views/app/dashboard/Index")
        }
      ]
    },
    {
      path: "*",
      redirect: "/app/dashboard"
    }
  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
