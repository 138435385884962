<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Eyestone-Admin",
      // all titles will be injected into this template
      titleTemplate:
        "%s | Eyestone Admin"
    };
  },
  created() {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", e => {
      console.log(mq);
      this.$vuetify.theme.dark = false; // Set to mq.matches if we want to base it on os theme
    });
  },
};
</script>
